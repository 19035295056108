import { create } from 'zustand';
import successReducer from './successReducer';
import errorReducer from './errorReducer';

var modalStore = create()(set => {
  return {
    modalState: 'init',
    loader: true,
    feedback: false,
    sessionAttempt: 0,
    merchant: {},
    bankList: [],
    update: newState =>
      set(state => {
        return { ...state, ...newState };
      })
  };
});

const api = {
  getState: modalStore.getState,
  setState: modalStore.setState
};

const allowedTypes = [
  'GET_TRANSACTION',
  'INIT_CHARGE_BANKTRANSFER',
  'CONFIRM_BANKTRANSFER',
  'INIT_CHARGE_CARD',
  'AUTH_VERIFY_CARD_PIN',
  'AUTH_VERIFY_CARD_OTP',
  'AUTH_VERIFY_CARD_AVS',
  'AUTH_VERIFY_CARD_PHONE',
  'CONFIRM_PAYMENT_LINK',
  'INIT_CHARGE_PAYMENTLINK',
  'CONFIRM_BANKTRANSFER',
  'QUERY_CARD_CHARGE',
  'QUERY_THREEDS_TYPE',
  'VERIFY_CARD_CHARGE',
  'VERIFY_THREEDS_TYPE',
  'QUERY_TRANSACTION',
  'AUTH_THREEDS_TEST',
  'INIT_CHARGE_BANK_DEBIT',
  'AUTHORIZE_BANK_DEBIT',
  'INIT_CHARGE_MOBILE_MONEY',
  'RESEND_CHARGE_MOBILE_MONEY',
  'AUTH_CHARGE_MOBILE_MONEY',
  'CONFIRM_MOBILE_MONEY',
  'CONFIRM_SANDBOX_MOBILE_MONEY',
  'RESEND_MOBILE_MONEY_STK',
  'GET_SAVEDCARDS',
  'DELETE_SAVEDCARD',
  'CONFIRM_PAYWITHBANK',
  'QUERY_MOBILE_MONEY_EGANOW',
  'REDIRECT_URL',
  'GET_CONVERSION_RATE'
];

const noLoaderTypes = [
  'CONFIRM_BANKTRANSFER',
  'CONFIRM_MOBILE_MONEY',
  'QUERY_CARD_CHARGE',
  'QUERY_THREEDS_TYPE',
  'QUERY_TRANSACTION',
  'CONFIRM_PAYWITHBANK',
  'RESEND_MOBILE_MONEY_STK',
  'QUERY_MOBILE_MONEY_EGANOW'
];

const sendMessage = async (type, apiFunction, loadingMessage = null) => {
  if (!allowedTypes.includes(type)) return null;
  if (!noLoaderTypes.includes(type)) api.setState({ ...api.getState(), loader: true, loadingMessage });
  try {
    const response = await apiFunction();
    const data = ['INIT_CHARGE_BANK_DEBIT', 'AUTHORIZE_BANK_DEBIT', 'RESEND_MOBILE_MONEY_STK'].includes(type) ? response : response.data;
    if (!response.status) return api.setState(errorReducer(type, response, api.getState()) || {}, response);
    return api.setState(successReducer(type, data, api.getState()) || {});
  } catch (error) {
    const response = error?.data || null;
    return api.setState(errorReducer(type, response, api.getState()) || {});
  }
};

export { modalStore, api, sendMessage };
