import React, { Dispatch, useLayoutEffect } from 'react';
import { AnalyticsTracker } from '../../../utils/analyticsTracker';
import { modalStore, sendMessage } from '../../../state';
import APIRequest from '../../../services/api-service';
import warning from '../../../assets/warning-icon.svg';

const api = new APIRequest();

interface ExpiredBanktypes {
  updateBankStage: Dispatch<React.SetStateAction<string>>;
}

export default function ExpiredBank({ updateBankStage }: ExpiredBanktypes) {
  const { update, public_key, payment_reference, page, allowMultipleChannels } = modalStore();

  useLayoutEffect(() => {
    update({ page: 'Termination' });
  }, []);

  return (
    <div className="custom-info">
      <div className="warning-image">
        <img src={warning} alt="warning icon" />
      </div>
      <p className="header">We are unable to confirm the transfer from your bank at the moment.</p>

      <div className="button-group">
        <button
          onClick={async () => {
            AnalyticsTracker.track('Try another transfer selected', {
              'Source Page': `${page} page`
            });
            update({
              hideHeading: false,
              bankDetails: null
            });
            updateBankStage('');
            return sendMessage('INIT_CHARGE_BANKTRANSFER', () =>
              api.initBankCharge({
                type: 'bank_transfer',
                data: {
                  public_key,
                  payment_reference
                }
              })
            );
          }}
        >
          Try another transfer
        </button>

        {allowMultipleChannels && (
          <button
            onClick={() => {
              AnalyticsTracker.track('Attempted to switch payment method', {
                'Source Page': `${page} page`
              });
              updateBankStage('');
              update({
                modalState: 'loaded',
                feedback: false,
                stage: 'init',
                bankDetails: null,
                buttonAction: 'close',
                page: 'Payment Method'
              });
            }}
          >
            Use a different method
          </button>
        )}
      </div>
    </div>
  );
}
