import { useRef } from 'react';
import info from '../../assets/info-icon.svg';
import check from '../../assets/check-icon.svg';

interface Message {
  card: {
    steps: string[];
    tip: string;
  };
  pay_with_bank: {
    steps: string[];
    tip: string;
  };
  bank_transfer: {
    steps: string[];
    tip: string;
  };
  pay_with_opay: {
    steps: string[];
    tip: string;
  };
  pay_with_palmpay: {
    steps: string[];
    tip: string;
  };
  pay_with_gtb: {
    steps: string[];
    tip: string;
  };
  mobile_money: {
    steps: string[];
    tip: string;
  };
}

interface TooltipType {
  type: string;
  currency: string;
}
const switchMobileMoneyByCurrency = (currency: string) => {
  switch (currency) {
    case 'KES':
      return {
        steps: ['Enter your mobile money wallet number.', 'We would send a request to your phone to authenticate your payment.'],
        tip: 'Once the payment is successful, you’ll get a confirmation.'
      };
    case 'GHS':
      return {
        steps: [
          'Enter your Mo-Mo mobile money wallet number.',
          'We require you to enter an OTP (One Time Password) to authorize the transaction.'
        ],
        tip: 'Once the payment is successful, you’ll get a confirmation.'
      };
    case 'NGN':
      return {
        steps: ['Enter your PayAttitude phone number.', 'We would send a request to your phone to authenticate your payment.'],
        tip: 'Once the payment is successful, you’ll get a confirmation.'
      };
    default:
      return {
        steps: [''],
        tip: ''
      };
  }
};

function ToolTip({ type, currency }: TooltipType) {
  const tooltipElement = useRef<null | HTMLDivElement>(null);

  const messages: Message = {
    card: {
      steps: ['Simply enter your debit details'],
      tip: 'With a successful charge, you’ll get a confirmation'
    },
    pay_with_bank: {
      steps: ['Select your preferred bank.', 'We’ll authenticate the transaction with your bank.'],
      tip: 'You’ll get a confirmation once the charge is successful.'
    },
    bank_transfer: {
      steps: [
        'We’ll generate a temporary account number for you.',
        'Go to your banking app/bank to initiate an instant transfer to the account.'
      ],
      tip: 'Once the transfer is successful, you’ll get a confirmation.'
    },
    pay_with_opay: {
      steps: ['Enter your Bank Details'],
      tip: 'With a successful charge, you’ll get a confirmation'
    },
    pay_with_palmpay: {
      steps: ['Enter your Bank Details'],
      tip: 'With a successful charge, you’ll get a confirmation'
    },
    pay_with_gtb: {
      steps: ['Enter your Bank Details'],
      tip: 'With a successful charge, you’ll get a confirmation'
    },
    mobile_money: switchMobileMoneyByCurrency(currency)
  };

  const showToolTip = (status: boolean) => {
    const element = tooltipElement.current as HTMLDivElement;
    if (status) {
      element.classList.add('visible');
      element.setAttribute('aria-hidden', 'true');
      (element.parentNode as HTMLDivElement).classList.add('visible');
      return;
    }
    element.classList.remove('visible');
    element.setAttribute('aria-hidden', 'false');
    (element.parentNode as HTMLDivElement).classList.remove('visible');
  };

  if (!messages[type as keyof Message]) return null;

  return (
    <div className="kpy-col__payment-tooltip" data-testid="payment-tooltip">
      <img
        src={info}
        alt="info icon"
        data-testid="tooltip-img"
        aria-label="steps tooltip"
        className="payment-tooltip--image"
        aria-describedby={`${type}-payment-tooltip`}
        tabIndex={0}
        onMouseOver={() => showToolTip(true)}
        onFocus={() => showToolTip(true)}
        onBlur={() => showToolTip(false)}
        onMouseLeave={() => showToolTip(false)}
      />
      <div className="payment-tooltip" id={`${type}-payment-tooltip`} role="tooltip" aria-hidden={false} ref={tooltipElement}>
        {messages[type as keyof Message]?.steps.map((step: string, index: number) => (
          <div key={step}>
            <span>{index + 1}</span>
            <p>{step}</p>
          </div>
        ))}
        <div>
          <span className="check-icon">
            <img src={check} alt="check icon" aria-label="final step" />
          </span>
          <p>{messages[type as keyof Message].tip}</p>
        </div>
      </div>
    </div>
  );
}

export default ToolTip;
