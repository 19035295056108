import { useEffect, useCallback } from 'react';
import { modalStore, sendMessage } from '../../../state';
import DeleteIcon from '../../../assets/delete-icon.svg';
import APIRequest from '../../../services/api-service';
import { switchProcessor } from '../../../utils/switches';
import { AnalyticsTracker } from '../../../utils';

const api = new APIRequest();

interface Tokenisedcard {
  card_type: string;
  expiry: string;
  first_six: string;
  last_four: string;
  reference: string;
  required_fields: string[];
}

const SavedCards = () => {
  const { payment_reference, update, cards, public_key, browser_card_token, customer } = modalStore();
  const handleRedirectToDeleteCard = (tokenisedcardreference: string, last_four: string) => {
    update({
      buttonAction: 'backToSavedCards',
      card: {
        stage: 'deletecard'
      },
      hideHeading: true,
      tokenisedcardreference,
      last_four
    });
  };

  const chargeCard = (reference: string) => {
    AnalyticsTracker.track('Payment made with tokenized card', null);
    return sendMessage('INIT_CHARGE_CARD', () =>
      api.chargeSavedCards({
        payment_reference,
        card_reference: reference,
        browser_card_token
      })
    );
  };

  const handleRedirectToCvvForm = useCallback((reference: string) => {
    update({
      card: {
        stage: 'cvv'
      },
      card_reference: reference,
      hideHeading: false,
      buttonAction: 'close'
    });
  }, []);

  useEffect(() => {
    if (browser_card_token) {
      sendMessage('GET_SAVEDCARDS', () =>
        api.getSavedCards({
          browser_card_token,
          email: customer?.email,
          public_key
        })
      );
    }
  }, [browser_card_token]);

  return (
    <>
      <div className="kpy-col__accordion">
        <p className="accordion-title">Select a saved card</p>
        <div className="payment-options">
          {cards ? (
            cards.map((each: Tokenisedcard) => (
              <div key={each.reference} className="saved-cards" tabIndex={0} role="button">
                <div
                  onClick={
                    each.required_fields.length > 0 && each.required_fields.includes('cvv')
                      ? () => handleRedirectToCvvForm(each.reference)
                      : () => chargeCard(each.reference)
                  }
                >
                  <img src={`data:image/svg+xml;base64,${btoa(switchProcessor[each.card_type].image)}`} alt="processor icon" />
                  <span>{`${each.first_six} ** **** ${each.last_four}`}</span>
                </div>
                <img
                  className="delete-icon"
                  width={'28px !important'}
                  style={{ fill: 'red' }}
                  onClick={() => handleRedirectToDeleteCard(each.reference, each.last_four)}
                  src={DeleteIcon}
                  alt="delete icon"
                />
              </div>
            ))
          ) : (
            <p className="accordion-title">No card saved</p>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedCards;
