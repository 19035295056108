import axios, { AxiosInstance } from 'axios';
import Bugsnag from '@bugsnag/js';

const APIBaseURL = process.env.VITE_MODAL_SERVER || 'http://localhost:6300';

export default class APIRequest {
  private instance: AxiosInstance;

  constructor(baseURL?: string) {
    this.instance = axios.create({
      baseURL: baseURL || APIBaseURL,
      timeout: 60000,
      headers: {
        Accept: 'application/json'
      }
    });

    this.instance.interceptors.response.use(
      response => {
        // hide response config
        return {
          data: response.data,
          status: response.status,
          statusText: response.statusText,
          headers: response.headers
        };
      },
      error => {
        return Promise.reject(
          Bugsnag.notify(error, event => {
            event.addMetadata('Request', {
              remoteBaseURL: error.config?.baseURL,
              remoteURL: error.config?.url
            });
          })
        );
      }
    );
  }

  setHeader = (token: string) => {
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  clearHeader() {
    delete this.instance.defaults.headers.common.Authorization;
  }

  async setUpTransaction(type: string, data: any) {
    const response = await this.instance.post(`/?type=${type}`, data);
    return response.data.data;
  }

  async validatePaymentLink(slug: string, env: string) {
    const response = await this.instance.post('/validate-link', { slug, env });
    return response.data.data;
  }

  async initMobileMoneyCharge(payload) {
    const response = await this.instance.post('/mobile-money/charge', payload);
    return response.data.data;
  }

  async authOTPMobileMoneyCharge(payload) {
    const response = await this.instance.post('/mobile-money/auth-otp', payload);
    return response.data.data;
  }

  async authOTPSandboxMobileMoneyCharge(payload) {
    const response = await this.instance.post('/mobile-money/auth-stk', payload);
    return response.data.data;
  }

  async resendOTPMobileMoneyCharge(payload) {
    const response = await this.instance.post('/mobile-money/resend-otp', payload);
    return response.data.data;
  }

  async resendSTKMobileMoneyCharge(payload) {
    const response = await this.instance.post('/mobile-money/resend-stk', payload);
    return response.data.data;
  }

  async queryMobileMoneyCharge(payload) {
    const response = await this.instance.post('/mobile-money/query', payload);
    return response.data.data;
  }

  async queryMobileMoneyEganow(payload) {
    const response = await this.instance.post('/mobile-money/query-direct', payload);
    return response.data.data;
  }

  async initBankCharge(payload) {
    const response = await this.instance.post('/bank/charge', payload);
    return response.data.data;
  }

  async queryBankTransferCharge(payload) {
    const response = await this.instance.post('/bank/query', payload);
    return response.data.data;
  }

  async resendPayWithBankOtp(payload) {
    const response = await this.instance.post('/bank/resend-otp', payload);
    return response.data.data;
  }

  async getBanks(currency, publicKey) {
    const response = await this.instance.get('/bank/banks', {
      params: { currency, publicKey }
    });
    return response.data.data;
  }

  async getAllBanks(countryCode, publicKey) {
    const response = await this.instance.get('/bank/all-banks', {
      params: { countryCode, publicKey }
    });
    return response.data.data;
  }

  async authorizeBankDebit(data) {
    const response = await this.instance.post('/bank/authorize', data);
    return response.data.data;
  }

  async requestProcessor(data) {
    const response = await this.instance.post('/bank/processor-request', data);
    return response.data.data;
  }

  async initChargeWithCard(payload) {
    const response = await this.instance.post('/card/charge', payload);
    return response.data.data;
  }

  async cardPaymentAuthorize(payload) {
    const response = await this.instance.post('/card/authorize', payload);
    return response.data.data;
  }

  async queryCardCharge(payload) {
    const response = await this.instance.post('/card/query', payload);
    return response.data.data;
  }

  async resendOtp(payload) {
    const response = await this.instance.post('/card/resend-otp', payload);
    return response.data.data;
  }

  async getSavedCards(payload) {
    const response = await this.instance.post('/card/get-savedcards', payload);
    return response.data?.data;
  }

  async chargeSavedCards(payload) {
    const response = await this.instance.post('/card/charge-savedcard', payload);
    return response.data?.data;
  }

  async deleteSavedCard(payload) {
    const response = await this.instance.delete('/card/delete-savedcard', { data: payload });
    return response.data.data;
  }

  async getThreeDSType(data) {
    const response = await this.instance.get(`/charge-card/threeds-type/${data.reference}`, {
      params: { type: typeof data === 'string' ? 'html' : 'json' }
    });
    return response.data.data;
  }

  async getCountries() {
    const response = await this.instance.get('/card/avs-countries');
    return response.data.data;
  }

  async getConversionRates(ref, publicKey) {
    const response = await this.instance.get('/card/get-rates', {
      params: { ref, publicKey }
    });
    return response.data.data;
  }

  async authorizeThreedsTest(data) {
    const response = await this.instance.post('/charge-card/threeds-test', data);
    return response.data.data;
  }

  async getSession(link: string) {
    const response = await this.instance.get(`/session/${link}`);
    return response.data.data;
  }

  async updateSession(data: { key: string; attempt: number }) {
    const response = await this.instance.post('/session', data);
    return response.data.data;
  }

  async getRedirectUrl(reference) {
    const response = await this.instance.get(`/bank/redirect-url`, { params: { reference } });
    return response.data.data;
  }
}
