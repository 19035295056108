import { useEffect, useRef } from 'react';
import { modalStore, sendMessage } from '../../../state';
import APIRequest from '../../../services/api-service';
import ThreeDSIframe from '../../card/components/threeds-modal';

const api = new APIRequest();

const PendingTransaction = () => {
  const { public_key, reference, update, openIframe, redirectUrl } =
    modalStore();
  const mobilemoneyQueryInterval = process.env.VITE_MOBILE_MONEY_QUERY_INTERVAL_IN_MILLISECONDS || 180000;
  const confirmPayment = useRef(null);
  const checkTransaction = () => {
    sendMessage('CONFIRM_MOBILE_MONEY', () =>
      api.queryMobileMoneyCharge({
        reference,
        env: process.env.VITE_ENV_TYPE,
        public_key
      })
    );
  };

  useEffect(() => {
    confirmPayment.current = setInterval(() => {
      checkTransaction();
    }, mobilemoneyQueryInterval);

    return () => {
      clearInterval(confirmPayment.current);
    };
  }, []);

  return (
    <section className="details-footer" style={{ marginTop: '7rem' }}>
      <div className="confirmation-spinner visible">
        <span />
      </div>
      <p style={{ minHeight: '100px' }}>
        Processing your payment.
        <br /> Please wait...
      </p>
      {openIframe && <ThreeDSIframe type={'url'} url={redirectUrl} cancelThreeDS={() => update({ openIframe: false })} />}
    </section>
  );
};

export default PendingTransaction;
