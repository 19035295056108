import React, { useState, useEffect, useRef, useLayoutEffect, MutableRefObject, Dispatch, SetStateAction } from 'react';
import { modalStore } from '../../../state';
import APIRequest from '../../../services/api-service';
import { AnalyticsTracker } from '../../../utils/analyticsTracker';
import { allowOnlyNumeric, calculateOTPTime } from '../../../utils/formats';

import checkmark from '../../../assets/checkmark-green.svg';
import loader from '../../../assets/kpy-loader.svg';
import { authDetailsType } from '../cardtypes';

const api = new APIRequest();

interface OtpTypes {
  authorizeCharge: (e: string) => void;
  setAuthDetails: Dispatch<SetStateAction<authDetailsType>>;
  otp: string;
  resetCardStage: () => void;
}

export default function Otp({ authorizeCharge, setAuthDetails, otp, resetCardStage }: OtpTypes) {
  const { isOtpIncorrect, errorMessage, otpMessage, testCard, reference, update, page, canResendOtp, metadata, paymentLink } = modalStore();

  useLayoutEffect(() => {
    update({ page: 'Card OTP' });
  }, []);

  const [otpState, setOtpState] = useState({
    disabled: false,
    activeRetry: false
  });
  const [resendOtpState, setResendOtpState] = useState({
    loading: false,
    feedback: false,
    visible: canResendOtp,
    activeResendCountdown: true,
    resendAttemptsLeft: 3,
    countdownTime: ''
  });
  const submitButtonRef = useRef<null | HTMLButtonElement>(null);
  const newOtpSessionRef = useRef(false);

  const timerInterval = useRef<null | NodeJS.Timeout>(null);

  const submitOtpForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton?.classList.contains('disabled')) {
        submitButton?.click();
      }
      return;
    }
  });

  const resetErrorMessage = useRef(() => {
    update({
      isOtpIncorrect: false,
      errorMessage: ''
    });
    newOtpSessionRef.current = false;
  });

  const authorizeOtp = () => {
    if (!testCard && (otp.length === 0 || otpState.disabled)) return;
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
    authorizeCharge('otp');
    setOtpState(prevOtpState => ({
      ...prevOtpState,
      disabled: true
    }));
  };

  const resendOtp = async () => {
    AnalyticsTracker.track('Resend OTP Attempt', {
      'Source Page': `${page} page`
    });
    // Activates the retry state for a new card
    if (!otpState.activeRetry) {
      setOtpState(prevOtpState => ({ ...prevOtpState, activeRetry: true }));
    }
    if (!resendOtpState.loading) {
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: true
      }));
    }
    try {
      const response = await api.resendOtp({
        reference,
        env: process.env.VITE_ENV_TYPE
      });
      if (response?.data?.error_type === 'PAYMENT_EXPIRED') {
        resetCardStage();
        resetErrorMessage.current();
        return;
      }
      if (response?.data?.error_type || !response?.data?.status) {
        setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          loading: false,
          activeResendCountdown: false,
          visible: false
        }));
        return;
      }
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: false,
        feedback: true,
        activeResendCountdown: prevResendOtpState.resendAttemptsLeft - 1 > 0,
        resendAttemptsLeft: prevResendOtpState.resendAttemptsLeft - 1,
        visible: prevResendOtpState.resendAttemptsLeft - 1 > 0
      }));
    } catch (error) {
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: false,
        activeResendCountdown: false,
        visible: false
      }));
    }
  };

  useEffect(() => {
    const submitOtp = submitOtpForm.current;
    window.addEventListener('keydown', submitOtp);

    (document.getElementById('card-otp') as HTMLInputElement).focus();
    return () => window.removeEventListener('keydown', submitOtp);
  }, []);

  useEffect(() => {
    if (isOtpIncorrect) {
      // Activates the retry state for a new card
      if (!otpState.activeRetry) {
        setOtpState(prevOtpState => ({ ...prevOtpState, activeRetry: true }));
      }
      setTimeout(() => (document.querySelector('.details-error') as HTMLDivElement)?.focus());
      setTimeout(() => (newOtpSessionRef.current = true));
    }
  }, [isOtpIncorrect, update, otpState.activeRetry]);

  useEffect(() => {
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
  }, [otp, isOtpIncorrect]);

  useLayoutEffect(() => {
    if (resendOtpState.activeResendCountdown) {
      const deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + 31);

      timerInterval.current = setInterval(() => {
        const timerValue = calculateOTPTime(deadline);
        if (timerValue === 'expired') {
          clearInterval(timerInterval.current);
          setResendOtpState(prevResendOtpState => ({
            ...prevResendOtpState,
            activeResendCountdown: false,
            countdownTime: ''
          }));
          return;
        }
        return setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          countdownTime: timerValue
        }));
      }, 1000);
    }

    return () => clearInterval(timerInterval.current);
  }, [resendOtpState.activeResendCountdown]);

  useEffect(() => {
    if (resendOtpState.feedback) {
      setTimeout(
        () =>
          setResendOtpState(prevResendOtpState => ({
            ...prevResendOtpState,
            feedback: false
          })),
        1500
      );
    }
  }, [resendOtpState.feedback]);

  return (
    <div className="kpy-col__pay-form --centered">
      <p className="details-head" id="card-otp-message">
        {otpMessage || 'Kindly enter the One-time PIN (OTP) sent to your phone number'}
      </p>
      {isOtpIncorrect && (
        <p className="details-error" tabIndex={-1}>
          {errorMessage || 'The OTP entered is invalid. Please try typing it again.'}
        </p>
      )}
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="card-otp" className="kpy-col-label">
            Enter One-time PIN (OTP)
          </label>
          <input
            aria-label="card-otp"
            aria-describedby="card-otp-message"
            className="kpy-col-input --card-details otp_input"
            name="auth"
            id="card-otp"
            type="text"
            pattern="^$|^[\d]+$"
            inputMode="numeric"
            maxLength={10}
            readOnly={Boolean(testCard)}
            value={testCard ? testCard.otp : otp}
            onChange={event => {
              const value = event.target.value;
              const validChars = /^$|^[\d]+$/.test(value || '');
              if (!validChars) return;
              setAuthDetails(prevAuthDetails => ({
                ...prevAuthDetails,
                otp: allowOnlyNumeric(value)
              }));
              setOtpState(prevOtpState => ({
                ...prevOtpState,
                disabled: false
              }));
            }}
            placeholder=""
          />
        </div>
      </section>
      <button
        className={`kpy-col-btn otp-form-btn ${!testCard && (otp.length === 0 || otpState.disabled) ? 'disabled' : ''}`}
        ref={submitButtonRef}
        style={{
          background: paymentLink?.checkout_customization?.primary_color,
          color: paymentLink?.checkout_customization?.secondary_color
        }}
        aria-disabled={!testCard && (otp.length === 0 || otpState.disabled)}
        onClick={authorizeOtp}
      >
        <span>Authorize</span>
      </button>

      <div className="retry-auth">
        {resendOtpState.feedback ? (
          <p className="resend-otp-feedback">
            <img src={checkmark} alt="success icon" />
            OTP Resent
          </p>
        ) : (
          <>
            <p style={{ lineHeight: '20px' }}>{metadata?.support_message ? `${metadata?.support_message}` : 'Didn’t receive any OTP?'} </p>
            {!resendOtpState.activeResendCountdown ? (
              <>
                {resendOtpState.visible && (
                  <button
                    className={`resend-otp-btn ${resendOtpState.loading ? 'disabled' : ''}`}
                    onClick={!resendOtpState.loading ? resendOtp : null}
                    aria-disabled={resendOtpState.loading}
                  >
                    Resend
                    {resendOtpState.loading && (
                      <>
                        {'ing'}
                        <img src={loader} alt="loading icon" aria-hidden />
                      </>
                    )}
                  </button>
                )}
              </>
            ) : (
              <span className="resend-otp-countdown">Wait {resendOtpState.countdownTime || '30 seconds'}</span>
            )}
          </>
        )}
        {otpState.activeRetry && (
          <div className="button-group">
            <button
              onClick={() => {
                AnalyticsTracker.track('Try another card selected', {
                  'Source Page': `${page} page`
                });
                resetErrorMessage.current();
                resetCardStage();
              }}
            >
              Try another card
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
