import { api } from '../state';
import { threeDSPopupClose, hasAccessToWindow, isInFrame, checkRedirectUrlForExtraParams } from '../utils';

export const handleOutgoing = (type: string, data: any) => {
  const { redirect_url, payment_reference } = api.getState();
  const redirectUrl = `${redirect_url}${checkRedirectUrlForExtraParams(redirect_url)}reference=${payment_reference}`;
  threeDSPopupClose();
  const info = JSON.stringify({ result: type, data });
  window.parent?.postMessage(info, '*');

  if (redirect_url && ['success'].includes(type))
    return setTimeout(() => {
      window.location.href = redirectUrl;
    }, 3000);

  if (redirect_url && ['close', 'redirect'].includes(type))
    return setTimeout(() => {
      window.location.href = redirectUrl;
    });
};

export const handleTabRedirect = () => {
  const { redirect_url, payment_reference, auth_model } = api.getState();
  const redirectUrl = `${redirect_url}${checkRedirectUrlForExtraParams(redirect_url)}reference=${payment_reference}`;
  if (auth_model === '3DS' && isInFrame() && hasAccessToWindow(window)) {
    // Handles external redirects for verification page in an iframe
    return setTimeout(() => {
      window.parent.location.href = redirectUrl;
    });
  }
  setTimeout(() => {
    window.location.href = redirectUrl;
  });
};
