import React, { useCallback, useEffect, useState } from 'react';
import APIRequest from '../../services/api-service';
import { modalStore, sendMessage } from '../../state';
import { AnalyticsTracker } from '../../utils/analyticsTracker';

import AddressForm from './card-actions/address';
import CardEnrollForm from './card-actions/card-enroll';
import CardInitForm from './card-actions/card-init';
import OtpForm from './card-actions/otp';
import PinForm from './card-actions/pin';
import ThreeDSForm from './card-actions/threeds';
import SavedCards from './card-actions/savedcard';
import DeleteCard from './card-actions/deletecard';

import { capitalize } from '../../utils/formats';
import { authDetailsType, cardDetails } from './cardtypes';
import TestCardScenarios from './components/testCards';
import CvvForm from './card-actions/cvv';

const sessionLimit = +(process.env.VITE_SESSION_ATTEMPT_LIMIT || 5);

const api = new APIRequest();

export default function Card() {
  const state = modalStore();
  const {
    card: { stage },
    card,
    testCard,
    payment_reference,
    public_key,
    reference,
    update,
    currency,
    browser_card_token,
    card_reference,
    sessionAttempt,
    unique_reference
  } = state;
  const [checked, setChecked] = useState(false);
  const [cardDetails, setCardDetails] = useState<cardDetails>({
    number: '',
    expiry_month: '',
    expiry_year: '',
    cvv: '',
    name: ''
  });
  const { name, ...newCardDetails } = cardDetails;
  let inputValue = cardDetails;
  if (currency === 'KES') {
    inputValue = cardDetails;
  } else {
    inputValue = newCardDetails;
  }

  const [authDetails, setAuthDetails] = useState<authDetailsType>({
    reference,
    otp: '',
    pin: ['', '', '', ''],
    cvv: '',
    avs: {
      country: '',
      address: '',
      state: '',
      city: '',
      zip_code: ''
    },
    phone: ''
  });

  const trackAuthorization = (type: string) => {
    if (type === 'pin') {
      AnalyticsTracker.track('Card Pin Inputted');
    }
    if (type === 'otp') {
      AnalyticsTracker.track('Authorized Payment: OTP', {
        'OTP Length': authDetails.otp.length
      });
    }
    if (type === 'avs') {
      AnalyticsTracker.track('Authorized Payment: AVS');
    }
    if (type === 'phone') {
      AnalyticsTracker.track('Authorized Payment: CARD_ENROLL');
    }
  };

  const chargeCard = async (cardType = 'not available') => {
    AnalyticsTracker.track('Card Payment Initiated');
    AnalyticsTracker.register({
      'Card Type': capitalize(cardType)
    });

    api.updateSession({
      key: unique_reference,
      attempt: +sessionAttempt + 1
    });

    update({
      card: {
        ...card,
        cardType
      },
      sessionAttempt: sessionAttempt + 1,
      storeCardForFutureUse: checked
    });

    if (sessionAttempt === +sessionLimit) return;
    if (testCard) {
      const { number, expiry_month, expiry_year, cvv } = testCard;
      return sendMessage('INIT_CHARGE_CARD', () =>
        api.initChargeWithCard({
          cardDetails: {
            number,
            expiry_month,
            expiry_year: expiry_year.slice(2),
            cvv
          },
          payment_reference,
          public_key
        })
      );
    } else {
      return sendMessage('INIT_CHARGE_CARD', () =>
        api.initChargeWithCard({
          cardDetails: {
            ...inputValue,
            expiry_year: cardDetails.expiry_year?.slice(2)
          },
          payment_reference,
          store_for_future_use: checked,
          public_key,
          ...(browser_card_token && checked && { browser_card_token })
        })
      );
    }
  };

  const authorizeCharge = useCallback(
    (type: string) => {
      trackAuthorization(type);

      if (testCard) {
        return sendMessage(`AUTH_VERIFY_CARD_${type.toUpperCase()}`, () =>
          api.cardPaymentAuthorize({
            public_key,
            reference,
            authorization: {
              [type]: testCard[type]
            }
          })
        );
      }
      return sendMessage(`AUTH_VERIFY_CARD_${type.toUpperCase()}`, async () => {
        const response = await api.cardPaymentAuthorize({
          public_key,
          reference,
          authorization: {
            [type]: Array.isArray(authDetails[type as keyof authDetailsType])
              ? authDetails[type as keyof authDetailsType].join('')
              : authDetails[type as keyof authDetailsType]
          }
        });
        if (response) {
          setAuthDetails({
            ...authDetails,
            [type]: type === 'pin' ? ['', '', '', ''] : ''
          });
        }
        return response;
      });
    },
    [reference, testCard, authDetails, public_key]
  );

  const chargeTokenisedCard = () => {
    return sendMessage('INIT_CHARGE_CARD', () =>
      api.chargeSavedCards({
        payment_reference,
        card_reference,
        browser_card_token,
        public_key,
        authorization: {
          cvv: authDetails.cvv
        }
      })
    );
  };

    const getConversionRates = () => {
     return sendMessage('GET_CONVERSION_RATE', () =>
       api.getConversionRates(
         payment_reference,
         public_key,
       )
     );
   };

  const resetCardStage = useCallback(() => {
    setCardDetails({
      number: '',
      expiry_month: '',
      expiry_year: '',
      cvv: ''
    });
    setAuthDetails(prevAuthDetails => ({
      ...prevAuthDetails,
      otp: '',
      pin: ['', '', '', ''],
      avs: {
        country: '',
        address: '',
        state: '',
        city: '',
        zip_code: ''
      },
      phone: ''
    }));
    if (stage !== 'init') {
      update({
        card: {
          stage: 'init',
          token_id: '',
          redirectUrl: '',
          cardType: ''
        },
        buttonAction: 'changePayment'
      });
    }
  }, [stage, update]);

  useEffect(() => {
    const setSandboxContent = () =>
      update({
        sandboxInstructions: <TestCardScenarios />
      });

    setSandboxContent();
  }, []);

  return (
    <>
      {stage === 'init' && (
        <CardInitForm
          onChecked={setChecked}
          cardDetails={cardDetails}
          setCardDetails={setCardDetails}
          chargeCard={chargeCard}
          resetCardStage={resetCardStage}
          refetchConversionRates={getConversionRates}
        />
      )}

      {stage === 'avs' && (
        <AddressForm addressDetails={authDetails.avs} setAuthDetails={setAuthDetails} authorizeCharge={authorizeCharge} />
      )}

      {stage === 'pin' && <PinForm pin={authDetails.pin} setAuthDetails={setAuthDetails} authorizeCharge={authorizeCharge} />}

      {stage === 'cvv' && <CvvForm cvv={authDetails.cvv} setAuthDetails={setAuthDetails} authorizeCharge={chargeTokenisedCard} />}

      {stage === 'otp' && (
        <OtpForm otp={authDetails.otp} setAuthDetails={setAuthDetails} authorizeCharge={authorizeCharge} resetCardStage={resetCardStage} />
      )}

      {stage === 'card_enroll' && (
        <CardEnrollForm phone={authDetails.phone} setAuthDetails={setAuthDetails} authorizeCharge={authorizeCharge} />
      )}
      {stage === 'deletecard' && <DeleteCard />}
      {stage === 'savedcards' && <SavedCards />}

      {stage === '3ds' && <ThreeDSForm resetCardStage={resetCardStage} />}
      {stage === 'no_auth' && (
        <div className="kpy-col__pay-form">
          <p className="details-error" tabIndex={-1}>
            There was an error while processing your payment. Please try again later.
          </p>
          <button className="kpy-col-btn" onClick={resetCardStage}>
            Retry
          </button>
        </div>
      )}
    </>
  );
}
