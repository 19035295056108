import React, { useRef, useLayoutEffect, useEffect, useCallback, useState } from 'react';
import posthog from 'posthog-js';
import { modalStore, sendMessage } from '../state';
import setupPusher from '../state/pusher';
import storage from '../utils/storage';
import APIRequest from '../services/api-service';

import Bank from './bank';
import BankTransfer from './bank-transfer/home';
import Card from './card';
import MobileMoney from './mobile-money';
import SandboxTooltip from './shared/sandbox-tooltip';
import ToolTip from './shared/tooltip';

import {
  AnalyticsTracker,
  capitalizeRemoveDash,
  environments,
  switchPaymentChannelImage,
  switchPaymentChannelImageType,
  smartTrack,
  identifyUser,
  trackEvent,
  featuresTracker
} from '../utils';

const api = new APIRequest();

function Accordion() {
  const {
    stage,
    bankDetails,
    currency,
    update,
    public_key,
    payment_reference,
    unique_reference,
    channels,
    default_channel,
    sandboxInstructions,
    customer,
    paymentLink,
    sessionAttempt,
    extraInstructions,
    extraInstructionsHeader,
    extraInstructionsfooterText,
    showExtraInstructionsOnRender,
    payment_channels_details
  } = modalStore();
  const [isOpayEnabledForUser, setIsOpayEnabledForUser] = useState<boolean | undefined>(false)
  const activeChannels: string[] = channels || ['bank_transfer'];
  const spotlightableProcessors = ['pay_with_opay', 'pay_with_palmpay', 'pay_with_gtb'];
  const spotlightedBank = payment_channels_details?.pay_with_bank?.spotlights || [];
  const paymentSession = useRef(false);
  const sessionlimit = process.env.VITE_SESSION_ATTEMPT_LIMIT || 5;
  useLayoutEffect(() => {
    update({ page: 'Payment Method' });
  }, []);


  useLayoutEffect(() => {
    setIsOpayEnabledForUser(posthog.isFeatureEnabled(featuresTracker.OPAY))
  }, []);

  useEffect(() => {
    const storedData = storage.getItem('titan');
    const findIndex = storedData.findIndex(item => item.email === customer.email && item.id === paymentLink?.merchant_id);
    if (findIndex !== -1) {
      return update({
        browser_card_token: storedData[findIndex]?.token
      });
    }
  }, []);

  useEffect(() => {
    if (customer.email) {
      identifyUser(customer.email, unique_reference);
    }
  }, [customer.email]);

  const activatePaymentSession = () => (paymentSession.current = true);

  const chargeBank = (processor: string) => {
    sendMessage('INIT_CHARGE_BANK_DEBIT', () =>
      api.initBankCharge({
        type: 'pay_with_bank',
        data: {
          public_key,
          payment_reference,
          bank_account: {
            processor: processor
          }
        }
      })
    );
  };

  const initializePaymentChannel = useCallback(
    (channel: string) => {
      setupPusher(unique_reference, update);
      AnalyticsTracker.register({
        'Payment Method': capitalizeRemoveDash(channel)
      });
      AnalyticsTracker.track('Payment Method Selected', null);
      smartTrack('Payment Method Selected', switchPaymentChannelImage(currency)[channel as keyof switchPaymentChannelImageType][1]);
      trackEvent(`Payment Method Selected: ${switchPaymentChannelImage(currency)[channel as keyof switchPaymentChannelImageType][1]}`);
      if (channel === 'bank_transfer') {
        update({
          stage: 'bank_transfer',
          hideHeading: false,
          buttonAction: 'helpActions'
        });
      }
      if (channel === 'card') {
        update({
          stage: channel,
          hideHeading: false,
          buttonAction: 'changePayment'
        });
        activatePaymentSession();
      }
      if (channel === 'mobile_money') {
        update({
          stage: channel,
          mobileMoney: {
            stage: channel
          },
          hideHeading: false,
          buttonAction: 'changePayment'
        });
        activatePaymentSession();
      }
      if (channel === 'pay_with_bank') {
        update({
          stage: channel,
          hideHeading: false,
          buttonAction: 'helpActions'
        });
        activatePaymentSession();
      }
      if (spotlightableProcessors.includes(channel)) {
        update({
          stage: 'pay_with_bank',
          hideHeading: false,
          buttonAction: 'helpActions'
        });
        activatePaymentSession();
      }
    },
    [bankDetails, payment_reference, update, public_key]
  );

  useEffect(() => {
    if (default_channel && stage === 'init' && !paymentSession.current) {
      initializePaymentChannel(default_channel);
    }
  }, [stage, default_channel, initializePaymentChannel]);

  const renderAccordion = () => {
    switch (stage) {
      case 'mobile_money':
        return <MobileMoney />;
      case 'pay_with_bank':
      case 'bank_help_actions':
        return <Bank />;
      case 'bank_transfer':
      case 'bank_transfer_help_actions':
        return <BankTransfer />;
      case 'card':
        return <Card />;
      default:
        return (
          <>
            <div className="kpy-col__accordion">
              <p className="accordion-title">How would you like to pay?</p>
              <div className="payment-options" data-testid="payment-options">
                {activeChannels.map(each => {
                  return (
                    <div
                      key={each}
                      className="payment-channel"
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        if (sessionAttempt > sessionlimit) {
                          update({
                            modalState: 'session-end'
                          });
                          return;
                        }
                        initializePaymentChannel(each);
                      }}
                    >
                      <img src={switchPaymentChannelImage(currency)[each as keyof switchPaymentChannelImageType][0] || ''} alt="" />
                      <span>{switchPaymentChannelImage(currency)[each as keyof switchPaymentChannelImageType][1]  || ''}</span>
                      <ToolTip type={each} currency={currency} />
                    </div>
                  );
                })}
                {spotlightedBank.map((each: string) => {
                  if (!isOpayEnabledForUser) return null;
                  return (
                    <div
                      key={each}
                      className="payment-channel"
                      tabIndex={0}
                      role="button"
                      onClick={() => {
                        if (sessionAttempt > sessionlimit) {
                          update({
                            modalState: 'session-end'
                          });
                          return;
                        }
                        api.updateSession({ key: unique_reference, attempt: +sessionAttempt + 1 });
                        AnalyticsTracker.track(`Spotlighted Pay With ${each} Clicked`, null);

                        update({
                          sessionAttempt: sessionAttempt + 1,
                          bankName: each
                        });
                        initializePaymentChannel(`pay_with_${each}`);
                        chargeBank(each);
                      }}
                    >
                      <img
                        style={{ width: '20px', height: '20px' }}
                        src={switchPaymentChannelImage(currency)[`pay_with_${each}` as keyof switchPaymentChannelImageType][0] || ''}
                        alt=""
                      />
                      <span>{switchPaymentChannelImage(currency)[`pay_with_${each}` as keyof switchPaymentChannelImageType][1] || ''}</span>
                      <span className="pay-with-bank">New</span>
                      <ToolTip type={`pay_with_${each}`} currency={currency} />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      {renderAccordion()}
      {process.env.VITE_ENV_TYPE === environments.TEST && sandboxInstructions && <SandboxTooltip content={sandboxInstructions} />}
      {extraInstructions && (
        <SandboxTooltip
          content={extraInstructions}
          header={extraInstructionsHeader}
          footerText={extraInstructionsfooterText}
          openAutomaticaly={showExtraInstructionsOnRender}
        />
      )}
    </>
  );
}

export default Accordion;
