import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { environments } from './formats';

const trackerType = (process.env.VITE_TRACKER as 'mixpanel' | 'posthog') || 'posthog';
const env_check = process.env.VITE_ENV_TYPE !== environments.TEST && process.env.NODE_ENV !== 'test';

if (trackerType === 'mixpanel' && process.env.VITE_MIXPANEL_ID && env_check) {
  mixpanel.init(process.env.VITE_MIXPANEL_ID);
} else if (trackerType === 'posthog' && process.env.VITE_POSTHOG_ID && env_check) {
  posthog.init(process.env.VITE_POSTHOG_ID, { api_host: process.env.VITE_POSTHOG_API_HOST });
}

const actionMap = {
  identify: {
    mixpanel: (id: string) => mixpanel.identify(id),
    posthog: (id: string) => posthog.identify(id)
  },
  track: {
    mixpanel: (name: string, props: Record<string, unknown>) => mixpanel.track(name, props),
    posthog: (name: string, props: Record<string, unknown>) => posthog.capture(name, props)
  },
  register: {
    mixpanel: (props: Record<string, unknown>) => mixpanel.register(props),
    posthog: (props: Record<string, unknown>) => posthog.register(props)
  },
  unregister: {
    mixpanel: (event: string) => mixpanel.unregister(event),
    posthog: (event: string) => posthog.unregister(event)
  },
  peopleSet: {
    mixpanel: (props: Record<string, unknown>) => mixpanel.people.set(props),
    posthog: (props: Record<string, unknown>) => posthog.people.set(props)
  },
  reset: {
    mixpanel: () => mixpanel.reset(),
    posthog: () => posthog.reset()
  }
};

const performAction = (action: keyof typeof actionMap, ...args: any[]) => {
  if (!env_check) return;

  try {
    const trackerAction = actionMap[action][trackerType];
    if (trackerAction) {
      trackerAction(...args);
    } else {
      console.warn(`[${action.toUpperCase()}]: Action not defined for tracker ${trackerType}`);
    }
  } catch (error) {
    console.warn(`[${action.toUpperCase()}]: There has been an error in this transaction's monitoring`, error);
  }
};

const actions = {
  identify: (id: string) => performAction('identify', id),
  track: (name: string, props: Record<string, unknown>) => performAction('track', name, props),
  register: (props: Record<string, unknown>) => performAction('register', props),
  unregister: (event: Record<string, unknown>) => performAction('unregister', event),
  people: {
    set: (props: Record<string, unknown>) => performAction('peopleSet', props)
  },
  reset: () => performAction('reset')
};

export const AnalyticsTracker = actions;
