export const defaultCountry = {
  KES: '254',
  GHS: '233',
  NGN: '234',
  XAF: '237',
  XOF: '225',
  EGP: '20',
};

export const mobileMoneySuccessTrxSandboxNumbers = {
  KES: '700 000 000',
  XAF: '655 123 456',
  XOF: '050 000 0000',
  GHS: '240 000 000'
};

export const mobileMoneyFailTrxSandboxNumbers = {
  KES: '734 611 986',
  XAF: '677 123 456',
  XOF: '012 345 6789',
  GHS: '274 611 986'
};
