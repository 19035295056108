import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import APIRequest from '../services/api-service';
import { modalStore, sendMessage } from '../state';
import Feedback from '../components/feedback';
import SessionEnd from '../components/session-end';
import loaderIcon from '../assets/RedirectLoader.svg';
import '../style/main.scss';

interface RouteParams {
  transactionReference: string;
}

const api = new APIRequest();

function RedirectPayment() {
  const { transactionReference: reference } = useParams<RouteParams>();
  const { loader, update, modalState, feedback } = modalStore();
  useLayoutEffect(() => {
    update({ page: 'Charge pay with bank Verification' });
  }, []);

  const getRedirectUrl = () => {
    sendMessage('REDIRECT_URL', () => api.queryBankTransferCharge({
      reference,
      env: process.env.VITE_ENV_TYPE
    }));
  };

  useLayoutEffect(() => {
    getRedirectUrl();
  }, []);

  if (modalState === 'session-end') {
    return <SessionEnd />;
  }

  return (
    <>
      <div className="kpy__root-container">
        {loader && (
          <section className="kpy-col-result">
            <p>
              <img src={loaderIcon} alt="Loading" />
            </p>
          </section>
        )}

        {feedback && <Feedback disableOutgoing={false} />}
      </div>
    </>
  );
}
export default RedirectPayment;
