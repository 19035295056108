import React, { useEffect, useLayoutEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { datadogRum } from '@datadog/browser-rum';
import APIRequest from '../services/api-service';
import { AnalyticsTracker, environments, unregisterServiceWorker } from '../utils';
import { modalStore, sendMessage } from '../state';
import { handleOutgoing } from '../services/external-hooks';

import PaymentLink from './PaymentLink';

import StarthereHeader from '../components/shared/start-here-header';
import Accordion from '../components/accordion';
import Heading from '../components/shared/heading';
import Feedback from '../components/feedback';
import Footer from '../components/footer';
import SessionEnd from '../components/session-end';

import close from '../assets/close-icon.svg';
import loaderIcon from '../assets/kpy-loader.svg';
import cardlogo from '../assets/card-logo.svg';

import '../style/main.scss';

const api = new APIRequest();

function App() {
  const pathArray = window.location.pathname.split('/');
  const { modalState, feedback, loader, update, stage, page, loadingMessage, sessionAttempt } = modalStore();
  const sessionlimit = +(process.env.VITE_SESSION_ATTEMPT_LIMIT || 5);

  useLayoutEffect(() => {
    unregisterServiceWorker().then(() => {
      AnalyticsTracker.reset();

      const key = pathArray[1] !== 'pay' ? pathArray[1] : '';

      api
        .getSession(key)
        .then(attempt => {
          if (Number(attempt)) {
            update({
              sessionAttempt: Number(attempt)
            });
          }
          if (Number(attempt) > sessionlimit) {
            update({
              modalState: 'session-end',
              sessionAttempt: sessionlimit,
              errorMessage: 'Unable to access payment link due to too many payment attempts'
            });
            throw Error('Session timeout');
          }
        })
        .then(() => {
          if (pathArray[1] === 'pay' && pathArray[2]) {
            datadogRum.setUser({
              id: pathArray[2]
            });
            sendMessage(
              'CONFIRM_PAYMENT_LINK',
              () => api.validatePaymentLink(pathArray[2], process.env.VITE_ENV_TYPE!),
              'Setting up your payment'
            );
          } else if (pathArray[1] && pathArray[1] !== 'pay') {
            datadogRum.setUser({
              id: pathArray[1]
            });
            sendMessage(
              'GET_TRANSACTION',
              () =>
                api.setUpTransaction('modal-payment', {
                  reference: pathArray[1],
                  env: process.env.VITE_ENV_TYPE
                }),
              'Setting up your payment'
            );
          } else {
            Bugsnag.notify(new Error('User inputted a wrong link'));
            update({
              feedback: true,
              loader: false,
              feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon_link.svg',
              modalState: 'Something’s wrong',
              errorMessage: "This payment's link seems to be incomplete or wrong, please check again."
            });
          }
        })
        .catch(e => {
          console.log(e);
          console.log('Session Timeout');
        });
    });
  }, []);

  useEffect(() => {
    if (sessionAttempt > sessionlimit) {
      update({
        modalState: 'session-end'
      });
      return;
    }
  }, [sessionAttempt]);

  if (modalState === 'session-end') {
    return <SessionEnd />;
  }

  return (
    <>
      {modalState === 'success' && <StarthereHeader />}
      <div className="kpy__root-container">
        {modalState !== 'init' && (
          <div className="secured__banner">
            <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
            <p>
              Secured by <strong>Kora</strong>
            </p>
          </div>
        )}
        {modalState === 'paymentLink' && <PaymentLink />}
        {modalState !== 'paymentLink' && (
          <div className="kpy-col__container">
            {modalState !== 'init' && process.env.VITE_ENV_TYPE === environments.TEST && (
              <div className="test-mode-indicator">
                <p>TEST MODE</p>
              </div>
            )}

            {loader && (
              <div className={`kpy--modal--inner ${modalState === 'init' ? 'top-level' : ''}`}>
                <div className="kpy-col_loader">
                  <div className="kpy-col_loader--content">
                    <img src={loaderIcon} alt="Loading" />
                    <p>{loadingMessage}</p>
                  </div>
                </div>
              </div>
            )}

            {(modalState === 'loaded' || modalState === 'threeds') && (
              <>
                <Heading />
                <Accordion />
                <Footer />
              </>
            )}

            {feedback && <Feedback />}

            <button
              className="kpy--close-btn"
              aria-label="End payment session"
              onClick={() => {
                AnalyticsTracker.track('Close button selected', {
                  'Source Page': `${page} page`
                });
                update({
                  modalState: 'session-end'
                });
                handleOutgoing('close', null);
              }}
            >
              <img src={close} alt="close icon" />
            </button>
          </div>
        )}
        <div hidden={feedback || modalState === 'init' || stage !== 'card'}>
          <div className="secure-icons">
            <img src={cardlogo} alt="Verified by Mastercard Secure and Visa" />
          </div>
        </div>
      </div>
    </>
  );
}
export default App;
