import Bugsnag from '@bugsnag/js';
import storage from '../utils/storage';
import { api } from './index';
import { AnalyticsTracker, smartTrack } from '../utils';

const setupPusher = (unique_reference, update) => {
  const store = api.getState();

  if (!window.Pusher) {
    console.log('Pusher is not loaded properly. Please reload the page');
    return Bugsnag.notify('Pusher not loaded properly on client');
  }

  window.Pusher.logToConsole = false;
  const pusherClient = new window.Pusher(process.env.VITE_SIRI_CROWN, {
    cluster: process.env.VITE_PUSHER_CLUSTER,
    forceTLS: true
  });

  const channel = pusherClient.subscribe(unique_reference.toUpperCase());

  pusherClient.connection.bind('error', err => {
    if (err?.error?.data?.code === 4004) {
      Bugsnag.notify('Pusher connection limit error');
    }
  });

  channel.bind('core-confirmation', data => {
    const { amount_charged, status, message, amount_collected, bank_transfer, metadata } = data;
    const defaultOptions = {
      amount: amount_charged,
      loader: false,
      feedback: true,
      buttonAction: 'none',
      bankTransferReversal: bank_transfer,
      feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/modal-icon-success.svg'
    };

    if (status === 'rejected') {
      //reject all
      return update({
        ...defaultOptions,
        amount_paid: amount_collected,
        modalState: 'return',
        outgoingStatus: status,
        buttonAction: 'otherOptions',
        bankDetails: null,
        page: 'Payment rejected',
        transactionLimit: bank_transfer?.transaction_limits || null,
        amount_reversed: bank_transfer?.amount_reversed,
        feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/modal-icon-warning.svg'
      });
    }

    if (status === 'success') {
        //return excess
        if (bank_transfer?.reversed){
         return update({
            ...defaultOptions,
            amount_paid: amount_collected,
            modalState: 'return excess',
            page: 'Payment success',
            outgoingStatus: status,
            amount_reversed: bank_transfer?.amount_reversed
          });
        }
        if (metadata?.browser_card_token){
        storage.setItem('titan', {email: store.customer.email, id:store?.paymentLink?.merchant_id , token: metadata?.browser_card_token});
        AnalyticsTracker.track('Successful Tokenization', {
          'User': store.customer.email
        })
        return update({
          ...defaultOptions,
          amount_paid: amount_collected,
          page: 'Payment success',
          modalState: 'success'
        });
      }
      //accept all
      smartTrack('Payment success', store.customer.email);
      return update({
        ...defaultOptions,
        amount_paid: amount_collected,
        page: 'Payment success',
        modalState: 'success',
        outgoingStatus: status,
        cardNotTokenized: true
      });
    }
    return update({
      ...defaultOptions,
      modalState: 'failed',
      outgoingStatus: 'failed',
      page: 'Payment failed',
      feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/modal-icon-failed.svg',
      errorMessage: message
    });
  });
};

export default setupPusher;
