import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { modalStore, sendMessage } from '../../state';
import useReducerState from '../../hooks/useReducerState';
import { initialState } from './bankHelper';
import {
  AnalyticsTracker,
  switchBanks,
  validateDate,
  ValidatePhoneNumber,
  cleanInput,
  allowOnlyNumeric,
  formatText,
  environments,
  convertDateFormatToMMDDYYYY,
  formatAmount,
  smartTrack
} from '../../utils';
import APIRequest from '../../services/api-service';
import ThreeDSIframe from '../card/components/threeds-modal';
import InputToolTip from '../shared/input-tooltip';
import ListDropdown from '../shared/list-dropdown';
import lock from '../../assets/lock.svg';
import Arrow from '../../assets/arrow.svg';

import BankHelp from '../shared/help';
import Copyable from '../shared/copyable';
import BankUnavailable from './bank-actions/cant-find-bank';
import BankAuthorization from './bank-actions/bank-authorise';

const api = new APIRequest();

const processors = ['mono', 'ozow', 'opay', 'gtb', 'flutterwave', 'aopay', 'palmpay', 'callpay'];

const BankHome = () => {
  const {
    update,
    stage,
    payment_reference,
    reference,
    public_key,
    errorMessage,
    pay_with_bank,
    sessionAttempt,
    openIframe,
    redirectUrl,
    amount,
    fees,
    currency,
    unique_reference
  } = modalStore();

  const helpMessages = [
    <>
      If your bank is not on the list of available banks for this payment method, we suggest you try a different payment method. We are
      continuously adding more banks to the list.
      <button
        className="kpy-btn--link help-action"
        hidden={process.env.VITE_ENV_TYPE === environments.TEST}
        onClick={() => {
          update({
            hideHeading: true,
            stage: 'pay_with_bank',
            buttonAction: 'changePayment',
            pay_with_bank: 'bank-unavailable'
          });
        }}
      >
        I can't find my bank on the list
      </button>
    </>,
    'If you are unable to authenticate the transaction with your bank, Kindly ensure that internet banking is enabled on your account. Contact your bank to learn more about this.'
  ];

  const [state, setState] = useReducerState({ ...initialState });
  const [active, setActive] = useState({ bank: false });

  useLayoutEffect(() => {
    update({ page: 'Bank selection page' });
  }, []);

  useLayoutEffect(() => {
    if (stage === 'bank_help_actions') {
      setState({ bankState: 'help' });
    } else {
      setState({ bankState: pay_with_bank || 'init' });
    }
  }, [stage, pay_with_bank]);

  const minimumMonoLimit = process.env.VITE_MONO_MINIMUM_TRANSACTION_LIMIT || 200;
  const expectedAmount = parseFloat(amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0);
  const isValidAmount = Number(minimumMonoLimit) > expectedAmount;

  const submitButtonRef = useRef(null);
  const fetchErrorCountRef = useRef(0);

  const fetchBanks = useCallback(async () => {
    setState({ isFetchingBanks: true });
    let filteredBanks;
    try {
      if (process.env.VITE_ENV_TYPE === environments.TEST) {
        filteredBanks = [{ name: 'Test Bank', slug: 'test', code: '000', processor: 'test' }];
        setState({ bankList: [{ name: 'Test Bank', slug: 'test', code: '000', processor: 'test' }] });
      } else {
        const { data: banks } = await api.getBanks(currency, public_key);
        filteredBanks = banks;
        setState({ bankList: filteredBanks });
      }
    } catch (error) {
      fetchErrorCountRef.current++;
      setState({
        bankError: `An error occured while getting the banks for this payment method. ${
          fetchErrorCountRef.current > 3 ? 'Please try another payment method.' : 'Please refresh the list'
        }`
      });
      setTimeout(() => {
        setState({ bankError: '' });
      }, 2500);
    } finally {
      setState({ isFetchingBanks: false, bankList: filteredBanks || [] });
    }
  }, []);

  useEffect(() => {
    if (state.bankList.length === 0) {
      fetchBanks();
    }
  }, [state.bankList, fetchBanks]);

  useEffect(() => {
    document.querySelector('.bank-dropdown')?.focus();
    const submitButton = submitButtonRef.current;
    window.addEventListener('keydown', submitButton);

    return () => window.removeEventListener('keydown', submitButton);
  }, []);

  useEffect(() => {
    if (state.bankDetails.bank_name) {
      const bank = state.bankList.find(bank => bank.name === state.bankDetails.bank_name);
      const bankStyle = switchBanks[bank?.slug];
      setState({ bankStyle, bankDetails: { ...state.bankDetails, processor: bank?.processor, code: bank?.code } });
      update({
        headingImage: bankStyle?.image
      });
    }
  }, [state.bankDetails.bank_name]);

  useEffect(() => {
    if (openIframe)
      smartTrack(`Redirected to ${state.bankDetails.processor}`, `Bank name - ${state.bankDetails.bank_name} redirectUrl - ${redirectUrl}`);
  }, [openIframe]);

  useEffect(() => {
    sandBoxContent();
  }, [state.bankState]);

  const isKudaMFB = state.bankDetails.bank_name === 'Kuda Micro-finance Bank';

  const isValidNumber = () => {
    if (!isKudaMFB && state?.bankDetails?.account_number.length > 0 && state.bankDetails.account_number.length !== 10) return false;
    if (isKudaMFB && state.bankDetails.phone_number.length > 0 && state.bankDetails.phone_number.length < 11) return false;
    if (isKudaMFB && state.bankDetails.phone_number.length > 10 && !ValidatePhoneNumber(234, state.bankDetails.phone_number)) return false;
    if (errorMessage?.includes('Source account inquiry')) return false;
    return true;
  };

  const isValidName = () => {
    if (state.bankDetails.account_name.length > 0 && state.bankDetails.account_name.length < 3) return false;
    return true;
  };

  const isValidDate = () => {
    const currentYear = new Date().getFullYear();
    const convertedDate = convertDateFormatToMMDDYYYY(state.bankDetails.date_of_birth);
    if (!validateDate(convertedDate)) return false;
    if (Number(convertedDate.split('/')[2]) >= currentYear) return false;
    return true;
  };

  const handleDisabled = () => {
    if (state.bankDetails.processor === 'mono') return isValidAmount;
    if (!processors.includes(state.bankDetails.processor)) {
      if (state.bankDetails.account_number === '' && state.bankDetails.phone_number === '') return true;
      if (['Zenith Bank Plc', 'United Bank for Africa'].includes(state.bankDetails.bank_name)) {
        return !isValidDate() || !isValidName() || !isValidNumber();
      }
      return !isValidNumber();
    }
    return false;
  };
  const PendingViews = () => {
    const timerInterval = useRef<null | NodeJS.Timeout>(null);
    const checkTransaction = () => {
      sendMessage('CONFIRM_PAYWITHBANK', () =>
        api.queryBankTransferCharge({
          reference: reference,
          env: process.env.VITE_ENV_TYPE,
          public_key
        })
      );
    };
    const confirmPayment = useRef<null | NodeJS.Timeout>(null);
    const [time, setTime] = useState(0);
    const payWithBankQueryInterevalinms = process.env.VITE_PAY_WITH_BANK_QUERY_INTERVAL || 120000;
    AnalyticsTracker.track('Bank payment pending', { Page: 'Pay with Bank' });

    useEffect(() => {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }

      timerInterval.current = setInterval(() => {
        if (time >= 2) {
          clearInterval(timerInterval.current);
          AnalyticsTracker.track('Pending terminal screen', { Page: 'Pending terminal screen' });
          update({
            feedback: true,
            loader: false,
            feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon_link.svg',
            modalState: 'Something’s wrong',
            errorMessage:
              'Sorry, we were unable to confirm this transaction from your bank. Please contact the business you are attempting to pay for help.'
          });
        } else {
          setTime(time + 1);
        }
      }, 60 * 1000);

      confirmPayment.current = setInterval(() => {
        checkTransaction();
      }, Number(payWithBankQueryInterevalinms));

      return () => {
        clearInterval(timerInterval.current);
        clearInterval(confirmPayment.current);
      };
    }, []);

    return (
      <section className="details-footer" style={{ marginTop: '7rem' }}>
        <div className="confirmation-spinner visible">
          <span />
        </div>

        {time < 1 ? (
          <p style={{ minHeight: '100px' }}>
            Processing your payment.
            <br /> Please wait...
          </p>
        ) : (
          <p style={{ minHeight: '100px' }}>This transaction is taking longer than usual. Please hold on for a few minutes...</p>
        )}
        {openIframe && <ThreeDSIframe type={'url'} url={redirectUrl} cancelThreeDS={() => update({ openIframe: false })} />}
      </section>
    );
  };

  const bankPaymentContent = () => {
    switch (state.bankState) {
      case 'help':
        return (
          <BankHelp
            helpMessages={helpMessages}
            goBack={() => {
              update({
                buttonAction: 'helpActions',
                stage: 'pay_with_bank',
                page: 'Pay with bank',
                hideHeading: false,
                pay_with_bank: 'init'
              });
            }}
          />
        );

      case 'bank-unavailable':
        AnalyticsTracker.track("Can't find bank", { Page: `Can't find bank` });
        return <BankUnavailable />;

      case 'bank-authorize':
        AnalyticsTracker.track('Bank debit authorisation', { Page: 'Bank Authorization page' });
        return <BankAuthorization />;

      case 'bank-pending':
        return <PendingViews />;

      default:
        return (
          <div className="kpy-col__pay-form">
            <p className="details-head" data-testid="details-head">
              Which bank would you like to pay with?
            </p>

            {state.bankError && (
              <p className="details-error" tabIndex={-1}>
                {state.bankError}
              </p>
            )}
            <section className="channel_container" style={state.bankStyle ? { backgroundColor: state.bankStyle.accent } : {}}>
              <div className="kpy-col-input-field">
                <label htmlFor="bank" className="kpy-col-label" style={state.bankStyle ? { color: state.bankStyle.color } : {}}>
                  Bank
                </label>
                <ListDropdown
                  type="bank"
                  active={active.bank}
                  setActive={setActive}
                  value={state.bankDetails.bank_name}
                  setValue={val => {
                    AnalyticsTracker.track('Bank selected', { 'Bank Name': val });
                    smartTrack('Bank selected', val);
                    setState({ bankDetails: { ...state.bankDetails, bank_name: val } });
                  }}
                  refetchList={fetchBanks}
                  isFetching={state.isFetchingBanks}
                  list={state.bankList}
                  finalItem={
                    <button
                      className="kpy-btn--link"
                      style={{ color: '#2476f3', backgroundColor: 'white' }}
                      onClick={() => {
                        update({
                          hideHeading: true,
                          buttonAction: 'changePayment',
                          pay_with_bank: 'bank-unavailable'
                        });
                      }}
                    >
                      Can't find your bank here?
                    </button>
                  }
                  className="kpy-col-input bank-dropdown"
                />
              </div>

              {!processors.includes(state.bankDetails.processor) && (
                <div className="kpy-col-input-field" hidden={!state.bankDetails.bank_name}>
                  <label htmlFor="account-number" className="kpy-col-label" style={state.bankStyle ? { color: state.bankStyle.color } : {}}>
                    {isKudaMFB ? 'Phone Number' : 'Account Number'}
                  </label>
                  <div className="field-wrapper">
                    <input
                      aria-label="account-number"
                      className={`kpy-col-input ${isValidNumber() ? '' : 'error'}`}
                      data-testid="account-number"
                      id="account-number"
                      name="account-number"
                      type="text"
                      placeholder="0000000000"
                      maxLength={13}
                      pattern="^[0-9]*$"
                      value={!isKudaMFB ? state.bankDetails.account_number : state.bankDetails.phone_number}
                      onChange={e => {
                        setState({
                          bankDetails: {
                            ...state.bankDetails,
                            account_number: isKudaMFB ? '' : allowOnlyNumeric(e.target.value),
                            phone_number: isKudaMFB ? cleanInput(e.target.value) : ''
                          }
                        });
                      }}
                    />
                    <InputToolTip
                      type="billing-zip-code"
                      full
                      dark
                      message={
                        <p id="billing-zip-code-input-tooltip">
                          Depending on your bank, this should be the credential that you use to log in to your internet banking application
                          (your Account Number, Phone Number, User ID, or Email).
                        </p>
                      }
                    />
                  </div>
                  <p id="bank-number-error" className="sr-only">
                    Please enter a valid {isKudaMFB ? 'phone number' : 'account number'}.
                  </p>
                  {errorMessage?.includes('Source account inquiry') && (
                    <p className="kpy-input-field-error bank-number-error">
                      Please enter a valid {isKudaMFB ? 'phone number' : 'account number'}.
                    </p>
                  )}
                </div>
              )}
              {['Zenith Bank Plc'].includes(state.bankDetails.bank_name) && (
                <div className="kpy-col-input-field">
                  <label htmlFor="account_name" className="kpy-col-label" style={state.bankStyle ? { color: state.bankStyle.color } : {}}>
                    Account Name
                  </label>
                  <div className="field-wrapper">
                    <input
                      aria-label="account_name"
                      className={`kpy-col-input ${isValidName() ? '' : 'error'}`}
                      id="account_name"
                      name="dob"
                      type="text"
                      placeholder="John Doe"
                      value={state.bankDetails.account_name}
                      onChange={e => {
                        setState({ bankDetails: { ...state.bankDetails, account_name: formatText(e.target.value) } });
                      }}
                    />
                  </div>
                </div>
              )}

              {['Zenith Bank Plc'].includes(state.bankDetails.bank_name) && (
                <div className="kpy-col-input-field">
                  <label htmlFor="dob" className="kpy-col-label" style={state.bankStyle ? { color: state.bankStyle.color } : {}}>
                    Date of Birth
                  </label>
                  <div className="field-wrapper">
                    <input
                      aria-label="dob"
                      className={`kpy-col-input ${state.bankDetails.date_of_birth.length > 0 && !isValidDate() && 'error'}`}
                      id="dob"
                      name="dob"
                      type="date"
                      placeholder="MM/DD/YYYY"
                      maxLength={10}
                      pattern="^[0-9/]*$"
                      value={state.bankDetails.date_of_birth}
                      onChange={e => {
                        setState({ bankDetails: { ...state.bankDetails, date_of_birth: e.target.value } });
                      }}
                    />
                  </div>
                  <p id="bank-date-error" className="sr-only">
                    Invalid date of birth
                  </p>
                  {state.bankDetails.date_of_birth.length > 0 && !isValidDate() && (
                    <p className="kpy-input-field-error bank-date-error">Invalid date of birth</p>
                  )}
                </div>
              )}
            </section>
            {state.bankDetails.processor === 'mono' && isValidAmount && (
              <p className="kpy-input-field-error transaction-limit-error">
                The minimum transaction amount for this bank is {`${currency} ${formatAmount(minimumMonoLimit)}`} Please try another bank or
                change your payment method.
              </p>
            )}

            <button
              className="kpy-col-btn"
              data-testid={'pay-with-bank-btn'}
              style={state.bankStyle ? { backgroundColor: state.bankStyle.color } : {}}
              ref={submitButtonRef}
              disabled={handleDisabled()}
              aria-disabled={handleDisabled()}
              hidden={!state.bankDetails.bank_name}
              onClick={() => {
                AnalyticsTracker.track('Pay with bank initiated', {
                  'Bank Name': state.bankDetails.bank_name,
                  [isKudaMFB ? 'Phone Number' : 'Account Number']: state.bankDetails[isKudaMFB ? 'phone_number' : 'account_number']
                });

                api.updateSession({ key: unique_reference, attempt: +sessionAttempt + 1 });

                update({
                  sessionAttempt: sessionAttempt + 1,
                  bankName: state.bankDetails.bank_name
                });
                sendMessage('INIT_CHARGE_BANK_DEBIT', () =>
                  api.initBankCharge({
                    type: 'pay_with_bank',
                    data: {
                      public_key,
                      payment_reference,
                      bank_account: {
                        processor: state.bankDetails.processor,
                        ...(!processors.includes(state.bankDetails.processor) && {
                          [isKudaMFB ? 'phone_number' : 'account_number']: state.bankDetails[isKudaMFB ? 'phone_number' : 'account_number']
                        }),
                        ...(['Zenith Bank Plc'].includes(state.bankDetails.bank_name) && {
                          date_of_birth: convertDateFormatToMMDDYYYY(state.bankDetails.date_of_birth)
                        }),
                        ...(['Zenith Bank Plc'].includes(state.bankDetails.bank_name) && {
                          account_name: state.bankDetails.account_name
                        }),
                        ...(['ozow'].includes(state.bankDetails.processor) &&
                          process.env.VITE_NODE_ENV === environments.PRODUCTION && {
                            bank_code: state.bankDetails.code
                          }),
                        ...(['mono', 'aopay', 'callpay'].includes(state.bankDetails.processor) && {
                          bank_code: state.bankDetails.code
                        })
                      }
                    }
                  })
                );
              }}
            >
              <img src={lock} alt="lock icon" aria-hidden />
              <span>Pay with {state.bankDetails.bank_name}</span>
            </button>
          </div>
        );
    }
  };

  const sandBoxContent = () => {
    switch (state.bankState) {
      case 'init':
        update({
          sandboxInstructions: (
            <>
              <p className="accordion-text">Use the test details below to simulate a successful transaction. </p>
              <div className="accordion-highlight">
                <span>Account Number</span>
                <Copyable text="0000000000" buttonClassName="accordion-copy" copyText="Copied ✓" />
              </div>
              <p className="accordion-text">Or any other 10 digit number to simulate an invalid account number.</p>
            </>
          )
        });
        break;
      case 'bank-authorize':
        update({
          sandboxInstructions: (
            <>
              <p className="accordion-text">Use the test details below to authenticate your transaction</p>
              <div className="accordion-highlight">
                <span>One-time Pin (OTP)</span>
                <Copyable text="123456" buttonClassName="accordion-copy" copyText="Copied ✓" />
              </div>
              <p className="accordion-text">Or any other 6 digit number to simulate an invalid OTP.</p>
            </>
          )
        });
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <button
        type="button"
        className="pwbank-goback"
        hidden={['init', 'bank-pending'].includes(state.bankState)}
        onClick={() => {
          update({
            buttonAction: 'helpActions',
            stage: 'pay_with_bank',
            page: 'Pay with bank',
            hideHeading: false,
            pay_with_bank: null
          });
        }}
      >
        <img src={Arrow} alt="go back" />
      </button>
      {bankPaymentContent()}
    </>
  );
};

export default BankHome;
