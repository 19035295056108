import Bugsnag from '@bugsnag/js';

export const copyWithJS = (key: string, updateFunction: React.Dispatch<React.SetStateAction<boolean>>) => {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = key;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body?.removeChild(selBox);
  return updateFunction;
};

export const onVerifyPage = (channel: string) => {
  return channel === 'modal' && process.env.VITE_THREEDS_KIND === 'html' && window.location.pathname.includes('/charge-card/verify/');
};

export const unregisterServiceWorker = async () => {
  try {
    if (!window.navigator?.serviceWorker?.controller) return;
    return window.navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration?.unregister();
      });
    });
  } catch (error: any) {
    Bugsnag.notify(error);
    return;
  }
};

export const hasChannelSwitch = (channels: string[]) => channels?.length > 1;

export const isInFrame = () => window.location !== window?.parent.location;

export const hasAccessToWindow = (window: Window) => {
  try {
    window?.parent?.document.querySelector('body');
    return true;
  } catch {
    return false;
  }
};

declare global {
  interface Window {
    clarity: (set?: string, event?: string, data?: string) => void;
  }
}

export const smartTrack = (event: string, data: string) => {
  if (window && typeof window.clarity === 'function') {
    window.clarity('set', event, data);
  } else {
    console.error('Clarity is not loaded');
  }
};

export const identifyUser = (customId: string, customSessionId: string) => {
  if (window && typeof window.clarity === 'function') {
    window.clarity('identify', customId, customSessionId);
  } else {
    console.error('Clarity is not loaded');
  }
};

export const trackEvent = (event: string) => {
  if (window && typeof window.clarity === 'function') {
    window.clarity('event', event);
  } else {
    console.error('Clarity is not loaded');
  }
};

type DebouncedFunction = (...args: any[]) => void;
export const debounce = (func: (...args: any[]) => void, wait: number): DebouncedFunction => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const checkRedirectUrlForExtraParams = (redirectUrl: string) => {
  if (!redirectUrl) return '';
  const redirectUrlArray = redirectUrl.split('?');
  return redirectUrlArray[1] ? '&' : '?';
};

// this function is for handling encrypting the message using the browser
// webcrypto. **It is not being used right now because we need more clarity **
// const encryptWithWebCrypto = async (info, publicKey) => {
//   const crypto = window.crypto || window.msCrypto;
//   if (!crypto) throw new Error("Messaging can only be done in a secure context (https)")
//   const encode = (str) => {
//     var buf = new ArrayBuffer(str.length * 2);
//     var bufView = new Uint16Array(buf);
//     for (var i = 0, strLen = str.length; i < strLen; i++) {
//       bufView[i] = str.charCodeAt(i);
//     }
//     return bufView;
//   };
//   // derive keyData and initialization vectors from publicKey
//   const [_, randomString] = publicKey.includes("pk_test_")
//     ? publicKey.split("pk_test_")
//     : publicKey.split("pk_live_");
//   const keyData = randomString.slice(0, 16);
//   const iv = encode(randomString.slice(randomString.length - 16));
//   // main encryption using AES-256
//   const key = await crypto.subtle.importKey(
//     "raw",
//     encode(keyData),
//     { name: "AES-GCM" },
//     false,
//     ["encrypt"]
//   );
//   return crypto.subtle.encrypt(
//     { name: "AES-GCM", iv },
//     key,
//     encode(info)
//   );
// };
