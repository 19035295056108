import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { modalStore } from '../../../state';
import APIRequest from '../../../services/api-service';
import { calculateOTPTime, otpRegex } from '../../../utils/formats';

import checkmark from '../../../assets/checkmark-green.svg';
import loader from '../../../assets/kpy-loader.svg';
import lock from '../../../assets/lock.svg';

const api = new APIRequest();

export default function MobileMoneyOTP({ processPayment, setAuthDetails, otp, resetMobileMoneyStage, maskPhoneNumber }) {
  const { isOtpIncorrect, errorMessage, otpMessage, reference, update, canResendOtp, public_key, paymentLink } = modalStore();

  const [otpState, setOtpState] = useState({
    disabled: false,
    activeRetry: false
  });
  const [resendOtpState, setResendOtpState] = useState({
    loading: false,
    feedback: false,
    visible: canResendOtp,
    activeResendCountdown: true,
    resendAttemptsLeft: 3,
    countdownTime: ''
  });
  const submitButtonRef = useRef(null);
  const newOtpSessionRef = useRef(false);

  const timerInterval = useRef(null);

  const submitOtpForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton.classList.contains('disabled')) {
        submitButton.click();
      }
      return;
    }
  });

  const resetErrorMessage = useRef(() => {
    update({
      isOtpIncorrect: false,
      errorMessage: ''
    });
    newOtpSessionRef.current = false;
  });

  const authorizeOtp = () => {
    if (otp?.length === 0 || otpState?.disabled) return;
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
    processPayment();
    setOtpState(prevOtpState => ({
      ...prevOtpState,
      disabled: true
    }));
  };

  const resendOtp = async () => {
    // Activates the retry state for a new mobile money
    if (!otpState.activeRetry) {
      setOtpState(prevOtpState => ({ ...prevOtpState, activeRetry: true }));
    }
    if (!resendOtpState.loading) {
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: true
      }));
    }
    try {
      const response = await api.resendOTPMobileMoneyCharge({
        public_key,
        reference,
        env: process.env.VITE_ENV_TYPE
      });
      if (response?.data?.error_type === 'PAYMENT_EXPIRED') {
        resetMobileMoneyStage();
        resetErrorMessage.current();
        return;
      }
      if (response?.data?.error_type || !response?.status) {
        setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          loading: false,
          activeResendCountdown: false,
          visible: false
        }));
        return;
      }

      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: false,
        feedback: true,
        activeResendCountdown: prevResendOtpState.resendAttemptsLeft - 1 > 0,
        resendAttemptsLeft: prevResendOtpState.resendAttemptsLeft - 1,
        visible: prevResendOtpState.resendAttemptsLeft - 1 > 0
      }));
    } catch (error) {
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: false,
        activeResendCountdown: false,
        visible: false
      }));
    }
  };

  useEffect(() => {
    const submitOtp = submitOtpForm.current;
    window.addEventListener('keydown', submitOtp);

    document.getElementById('mobile-money-otp').focus();
    return () => window.removeEventListener('keydown', submitOtp);
  }, []);

  useEffect(() => {
    if (isOtpIncorrect) {
      // Activates the retry state for a new mobile money number
      if (!otpState.activeRetry) {
        setOtpState(prevOtpState => ({ ...prevOtpState, activeRetry: true }));
      }
      setTimeout(() => document.querySelector('.details-error')?.focus());
      setTimeout(() => (newOtpSessionRef.current = true));
    }
  }, [isOtpIncorrect, update, otpState.activeRetry]);

  useEffect(() => {
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
  }, [otp, isOtpIncorrect]);

  useLayoutEffect(() => {
    if (resendOtpState.activeResendCountdown) {
      const deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + 31);

      timerInterval.current = setInterval(() => {
        const timerValue = calculateOTPTime(deadline);
        if (timerValue === 'expired') {
          clearInterval(timerInterval.current);
          setResendOtpState(prevResendOtpState => ({
            ...prevResendOtpState,
            activeResendCountdown: false,
            countdownTime: ''
          }));
          return;
        }
        return setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          countdownTime: timerValue
        }));
      }, 1000);
    }

    return () => clearInterval(timerInterval.current);
  }, [resendOtpState.activeResendCountdown]);

  useEffect(() => {
    if (resendOtpState.feedback) {
      setTimeout(
        () =>
          setResendOtpState(prevResendOtpState => ({
            ...prevResendOtpState,
            feedback: false
          })),
        1500
      );
    }
  }, [resendOtpState.feedback]);

  return (
    <div className="kpy-col__pay-form --centered">
      <p className="details-head" id="mobile-money-otp-message">
        {otpMessage || (
          <>
            Enter the One-time PIN (OTP) sent to your phone <br /> {maskPhoneNumber} to authorize this transaction.
          </>
        )}
      </p>
      {isOtpIncorrect && (
        <p className="details-error" tabIndex="-1">
          {errorMessage || 'The OTP entered is invalid. Please try typing it again.'}
        </p>
      )}
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="mobile-money-otp" className="kpy-col-label text-left">
            Enter One-time PIN (OTP)
          </label>
          <input
            aria-label="mobile-money-otp"
            aria-describedby="mobile-money-otp-message"
            className="kpy-col-input --mobile-money-details otp_input"
            name="auth"
            id="mobile-money-otp"
            type="text"
            pattern={otpRegex}
            inputMode="numeric"
            maxLength={10}
            value={otp}
            onChange={event => {
              const value = event.target.value;
              const validChars = /^$|^[\d]+$/.test(value || '');
              if (!validChars) return;
              setAuthDetails(prevAuthDetails => ({
                ...prevAuthDetails,
                otp: value
              }));
              setOtpState(prevOtpState => ({
                ...prevOtpState,
                disabled: false
              }));
            }}
            placeholder=""
          />
        </div>
      </section>
      <button
        className={`kpy-col-btn otp-form-btn ${otp?.length === 0 || otpState.disabled ? 'disabled' : ''}`}
        ref={submitButtonRef}
        aria-disabled={otp?.length === 0 || otpState.disabled}
        onClick={authorizeOtp}
        style={{
          background: paymentLink?.checkout_customization?.primary_color,
          color: paymentLink?.checkout_customization?.secondary_color
        }}
      >
        <img src={lock} alt="lock icon" aria-hidden />
        <span>Authorize</span>
      </button>

      <div className="retry-auth">
        {resendOtpState.feedback ? (
          <p className="resend-otp-feedback">
            <img src={checkmark} alt="success icon" />
            OTP Resent
          </p>
        ) : (
          <p>
            Didn’t receive any OTP?{' '}
            {!resendOtpState.activeResendCountdown ? (
              <>
                {resendOtpState.visible && (
                  <button
                    className={`resend-otp-btn ${resendOtpState.loading ? 'disabled' : ''}`}
                    onClick={!resendOtpState.loading ? resendOtp : null}
                    aria-disabled={resendOtpState.loading}
                  >
                    Resend
                    {resendOtpState.loading && (
                      <>
                        {'ing'}
                        <img src={loader} alt="loading icon" aria-hidden />
                      </>
                    )}
                  </button>
                )}
              </>
            ) : (
              <span className="resend-otp-countdown">Wait {resendOtpState.countdownTime || '30 seconds'}</span>
            )}
          </p>
        )}
        {otpState.activeRetry && (
          <div className="button-group">
            <button
              onClick={() => {
                resetErrorMessage.current();
                resetMobileMoneyStage();
                update({
                  mobileMoney: {
                    stage: 'mobile_money'
                  },
                  buttonAction: 'helpActions'
                });
              }}
            >
              Try another mobile money number
            </button>
          </div>
        )}
        <br />
      </div>
    </div>
  );
}
