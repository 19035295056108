import loaderIcon from '../assets/kpy-loader.svg';
import '../style/main.scss';



function LoadingScreen() {
  return (
    <>
      <div className="kpy__root-container">
        <div className="secured__banner">
          <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
          <p>
            Secured by <strong>Kora</strong>
          </p>
        </div>
          <section className="kpy-col-result">
            <p>
              <img src={loaderIcon} alt="Loading" />
              <p>loading...</p>
            </p>
          </section>
      </div>
    </>
  );
}
export default LoadingScreen;
